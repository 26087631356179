export const STATUSLIST = (t) => [
  { _id: 'all', name: t('all') },
  { _id: '1', name: t('active') },
  { _id: '2', name: t('inActive') },
  { _id: '3', name: t('deleted') },
];

export const ATTENDENCEOPTION = (t) => [
  { value: '1', name: t('Present') },
  { value: '2', name: t('Absent') },
];

export const GENDER = [
  { value: 'Male', name: 'Male' },
  { value: 'Female', name: 'Female' },
  { value: 'Others', name: 'Others' },
];

export const SESSION = [
  { _id: '1', name: 'Forenoon' },
  { _id: '2', name: 'Afternoon' },
  { _id: '3', name: 'Full Day' },
];

export const MARITAL_STATUS = [
  { _id: '1', name: 'Single' },
  { _id: '2', name: 'Married' },
  { _id: '3', name: 'Divorced' },
  { _id: '4', name: 'Widowed' },
];

export const BLOOD_GROUP = [
  { value: 'A+', name: 'A+' },
  { value: 'A-', name: 'A-' },
  { value: 'B+', name: 'B+' },
  { value: 'B-', name: 'B-' },
  { value: 'AB+', name: 'AB+' },
  { value: 'AB-', name: 'AB-' },
  { value: 'O+', name: 'O+' },
  { value: 'O-', name: 'O-' },
];

export const SESSION1 = [
  { _id: '1', name: 'Forenoon' },
  { _id: '2', name: 'Afternoon' },
];
export const DURATIONLIST = [
  { _id: '1', name: 'Yearly' },
  { _id: '2', name: 'Monthly' },
];

export const LANGUAGELIST = [
  { _id: '1', name: 'English', value: 'en-US' },
  { _id: '5', name: 'Tamil', value: 'ta' }, // Standard language for Singapore
];

export const LEAVEOPTION = [
  {
    value: '1',
    name: 'Casual leave',
  },
  {
    value: '2',
    name: 'Permission',
  },
  {
    value: '3',
    name: 'Sick Leave',
  },
  {
    value: '4',
    name: 'Maternity Leave',
  },
  {
    value: '5',
    name: 'Paternity Leave',
  },
  {
    value: '6',
    name: 'Marriage Leave',
  },
  {
    value: '7',
    name: 'Work From Home',
  },
  {
    value: '8',
    name: 'Absent',
  },
];
