import {
  Table,
  FloatButton,
  Typography,
  Select,
  DatePicker,
  Button,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import dayjs from 'dayjs';
import { AiOutlineExport } from 'react-icons/ai';
import { saveAs } from 'file-saver'; // Import file-saver for file download
import * as XLSX from 'xlsx'; // Import xlsx for Excel file creation

export default function DashAttendanceYearlyReport() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(1);
  const [attendance, setAttendance] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usersId, setUserId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (roles?.includes('admin')) {
      fetchAttendanceList(searchParams);
      fetchUsersList();
      fetchHolidaysList();
    }
  }, [location.search, usersId]);

  const fetchHolidaysList = async () => {
    try {
      setLoading(true);
      const URL = PATHS.HOLIDAY.GET + `?status=all`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setHolidaysList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('err in dash config', error.message);
    }
  };

  const fetchAttendanceList = async () => {
    try {
      setLoading(true);
      const URL = `${PATHS.ATTENDANCE.GET}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        console.log('data ++', data.filteredResponse);
        setAttendance(data.filteredResponse);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const fetchUsersList = async () => {
    try {
      setLoading(true);
      const URL = PATHS.USER.GET + `?limit=1000&sort=first_name&order=asc`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUsersList(data.users);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleStatusFilterChange = (pagination, filters) => {
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };

  const selectUsers = (selectedList) => {
    const searchParams = new URLSearchParams(location.search);
    if (selectedList) {
      setUserId(selectedList);
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: selectedList,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        userId: '',
      });
    }
  };

  const handleDateRangeChange = (date) => {
    if (date) {
      const startOfMonth = dayjs(date).startOf('month');
      const endOfMonth = dayjs(date).endOf('month');
      setSelectedMonth(date);
      updateURL({
        startDate: startOfMonth.format('DD-MM-YYYY'),
        endDate: endOfMonth.format('DD-MM-YYYY'),
      });
    } else {
      setSelectedMonth(dayjs());
      updateURL({
        startDate: '',
        endDate: '',
      });
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const handleExport = () => {
    const exportData = usersList.map((user) => {
      console.log('user+++', user._id);
      const summary = calculateSummaryColumns(user);
      const userAttendance = {
        'Employee Name': user.username,
        'Working Days': summary.workingDays,
        'Days Present': summary.daysPresent,
        'Days Absent': summary.daysAbsent,
      };

      const daysInMonth = dayjs(selectedMonth).daysInMonth();
      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = dayjs(selectedMonth).date(day);
        const formattedDate = currentDate.format('DD-MM-YYYY');
        const dayAttendance = attendance.find(
          (att) =>
            att?.userId?._id == user._id &&
            dayjs(att.date).isSame(currentDate, 'day'),
        );
        userAttendance[formattedDate] = dayAttendance
          ? dayAttendance.status === 2
            ? dayAttendance.type === 1
              ? 'Casual Leave'
              : dayAttendance.type === 2
                ? 'Permission'
                : dayAttendance.type === 3
                  ? 'Sick Leave'
                  : dayAttendance.type === 4
                    ? 'Maternity Leave'
                    : dayAttendance.type === 5
                      ? 'Paternity Leave'
                      : dayAttendance.type === 6
                        ? 'Marriage Leave'
                        : 'Absent'
            : 'Present'
          : 'P';
      }
      return userAttendance;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance');
    const workbookOut = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    saveAs(
      new Blob([workbookOut], { type: 'application/octet-stream' }),
      `Attendance_${dayjs(selectedMonth).format('MMMM_YYYY')}.xlsx`,
    );
  };

  const calculateSummaryColumns = (record) => {
    const daysInMonth = dayjs(selectedMonth).daysInMonth();
    // const currentMonth = dayjs().startOf('month');
    // const selectedMonthStart = dayjs(selectedMonth).startOf('month');

    // if (!selectedMonthStart.isSame(currentMonth, 'month')) {
    //   return { workingDays: null, daysPresent: null, daysAbsent: null };
    // }

    let workingDays = 0;
    let daysAbsent = 0;

    const holidayMap = holidaysList.reduce((map, holiday) => {
      const holidayDate = dayjs(holiday.date).format('YYYY-MM-DD');
      map[holidayDate] = true;
      return map;
    }, {});

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = dayjs(selectedMonth).date(day);
      const dayOfWeek = currentDate.format('ddd');
      const formattedDate = currentDate.format('YYYY-MM-DD');

      // Skip weekends and holidays
      if (
        dayOfWeek === 'Sat' ||
        dayOfWeek === 'Sun' ||
        holidayMap[formattedDate]
      ) {
        continue;
      }

      workingDays++;

      const dayAttendance = attendance.find(
        (att) =>
          att?.userId?._id == record._id &&
          dayjs(att.date).isSame(currentDate, 'day'),
      );
      // console.log('record+++', dayAttendance.user._id)

      if (
        dayAttendance &&
        dayAttendance.status === 2 &&
        dayAttendance.type !== 2
      ) {
        if (dayAttendance.session === 1 || dayAttendance.session === 2) {
          daysAbsent += 0.5;
        } else if (dayAttendance.type === 7 && dayAttendance.type === 2) {
          daysAbsent += 0;
        } else {
          daysAbsent += 1;
        }
      }
    }

    const daysPresent = workingDays - daysAbsent;

    return { workingDays, daysPresent, daysAbsent };
  };

  const summaryColumns = [
    {
      title: t('No of Working Days'),
      key: 'workingDays',
      render: (_, record) => {
        const { workingDays } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {workingDays !== null ? workingDays : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of Days Present'),
      key: 'daysPresent',
      render: (_, record) => {
        const { daysPresent } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {daysPresent !== null ? daysPresent : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of Days Absent'),
      key: 'daysAbsent',
      render: (_, record) => {
        const { daysAbsent } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {daysAbsent !== null ? daysAbsent : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  const baseColumns = [
    {
      title: t('Employees Name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      filteredValue: null,
      fixed: 'left',
    },
  ];

  // const dynamicColumns = generateDaysColumns();

  const columns = [
    ...baseColumns,
    // ...dynamicColumns
    ...summaryColumns,
  ];
  return (
    <div className="w-2/3 md:mx-auto p-3">
      <FloatButton.BackTop />
      <div className="flex flex-col md:flex-row md:justify-between items-end gap-2 py-4 mt-2">
        <div className="flex md:w-1/2 flex-row items-start gap-2">
          <div className="w-full  flex flex-col gap-2">
            <Typography value={t('Select Month')} />
            <DatePicker
              onChange={handleDateRangeChange}
              selectedValue={selectedMonth}
              picker="month"
              format="MMM-YYYY"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectUser')} />
            <Select
              options={usersList}
              className={'w-full'}
              onChange={selectUsers}
              placeholder={t('selectaUser')}
            />
          </div>
        </div>
        <Button
          type="primary"
          className="items-center justify-center"
          icon={<AiOutlineExport />}
          onClick={handleExport}
        >
          {t('Export')}
        </Button>
      </div>

      {roles?.includes('admin') && (
        <div className="w-[100%]">
          <div className="overflow-x-auto">
            <Table
              dataSource={usersList}
              columns={columns}
              className="w-full"
              onChange={handleStatusFilterChange}
              pagination={{
                current: pageCount,
                pageSize: 500,
              }}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
}
