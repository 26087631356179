import { MdDeleteOutline } from 'react-icons/md';
import { Tag, Popconfirm } from '../antd-components/index.tsx';
import { Link } from 'react-router-dom';
import { CiEdit } from 'react-icons/ci';
import { FaEye } from 'react-icons/fa';

const getStatusColumn = (t, selectedStatus) => ({
  title: t('status'),
  dataIndex: 'status',
  key: 'status',
  filters: [
    { text: t('active'), value: 1 },
    { text: t('inActive'), value: 2 },
    { text: t('deleted'), value: 3 },
  ],
  onFilter: (value, record) => record.status === value,
  filterMultiple: false,
  defaultFilteredValue: [1],
  filteredValue: Array.isArray(selectedStatus)
    ? selectedStatus
    : [selectedStatus],
  render: (text) =>
    text === 1 ? (
      <Tag active="1">{t('active')}</Tag>
    ) : text === 2 ? (
      <Tag active="2">{t('inActive')}</Tag>
    ) : (
      <Tag active="3">{t('deleted')}</Tag>
    ),
});

const getEditColumn = (t, routesnurtem) => ({
  title: t('edit'),
  key: 'edit',
  render: (text, record) => (
    <Link
      to={`${routesnurtem}${record._id}`}
      className="text-teal-500 hover:underline"
    >
      <CiEdit size={20} color="blue" />
    </Link>
  ),
});

const getDeleteColumn = (t, handleDeleteUser) => ({
  title: t('delete'),
  key: 'delete',
  render: (text, record) => (
    <Popconfirm
      title={t('are-you-delete-this')}
      onConfirm={() => handleDeleteUser(record._id)}
      okText={t('yes-im-sure')}
      cancelText={t('no-cancel')}
    >
      <span className="cursor-pointer">
        <MdDeleteOutline size={20} color="red" />
      </span>
    </Popconfirm>
  ),
});

const getViewColumn = (t, routesnurtem) => ({
  title: t('view'),
  key: 'view',
  render: (text, record) => (
    <Link to={`${routesnurtem}${record._id}`}>
      <FaEye size={20} color="blue" />
    </Link>
  ),
});

const getAttendanceStatusColumn = (t, selectedStatus) => ({
  title: t('status'),
  dataIndex: 'status',
  key: 'status',
  filters: [
    { text: t('present'), value: 1 },
    { text: t('absent'), value: 2 },
  ],
  onFilter: (value, record) => record.status === value,
  filterMultiple: false,
  defaultFilteredValue: [1],
  filteredValue: Array.isArray(selectedStatus)
    ? selectedStatus
    : [selectedStatus],
  render: (text) =>
    text === 1 ? (
      <Tag active="1">{t('present')}</Tag>
    ) : (
      <Tag active="2">{t('absent')}</Tag>
    ),
});

const getSessionColumn = (t, selectedSession) => ({
  title: t('Session'),
  dataIndex: 'session',
  key: 'session',
  filters: [
    { text: t('fullday'), value: 3 },
    { text: t('forenoon'), value: 1 },
    { text: t('afternoon'), value: 2 },
  ],
  onFilter: (value, record) => record.session === value,
  filterMultiple: false,
  filteredValue: Array.isArray(selectedSession)
    ? selectedSession
    : [selectedSession],
  render: (text) =>
    text === 1 ? (
      <Tag active="5">{t('forenoon')}</Tag>
    ) : text === 2 ? (
      <Tag active="6"> {t('afternoon')}</Tag>
    ) : (
      <Tag active="4">{t('fullday')}</Tag>
    ),
});

const getLeaveTypeColumn = (t, selectedLeaveType) => ({
  title: t('typeofleave'),
  dataIndex: 'type',
  key: 'type',
  filters: [
    { text: t('casualleave'), value: 1 },
    { text: t('permission'), value: 2 },
    { text: t('sick'), value: 3 },
    { text: t('maternaity'), value: 4 },
    { text: t('parentity'), value: 5 },
    { text: t('marriage'), value: 6 },
    { text: t('WFH'), value: 7 },
    { text: t('Absent'), value: 8 },
  ],
  onFilter: (value, record) => record.type === value,
  filterMultiple: false,
  filteredValue: Array.isArray(selectedLeaveType)
    ? selectedLeaveType
    : [selectedLeaveType],
  render: (text) =>
    text === 1
      ? t('casualleave')
      : text === 2
        ? t('permission')
        : text === 3
          ? t('sick')
          : text === 4
            ? t('maternaity')
            : text === 5
              ? t('parentity')
              : text === 6
                ? t('marriage')
                : text === 7
                  ? t('WFH')
                  : t('Absent'),
});

export {
  getStatusColumn,
  getEditColumn,
  getDeleteColumn,
  getViewColumn,
  getAttendanceStatusColumn,
  getSessionColumn,
  getLeaveTypeColumn,
};
